<template>
  <div class="meals">
    <main-app-bar>
      <template v-slot:title> Meal Guides </template>

      <template v-slot:actions>
        <div class="app-bar--bottom-actions nav-pills">
          <v-btn :to="{ name: 'active.meals' }" depressed replace>
            Active Meal Guides
          </v-btn>

          <v-btn :to="{ name: 'draft.meals' }" depressed replace>
            Draft Meal Guides
          </v-btn>

          <v-btn :to="{ name: 'deleted.meals' }" depressed replace>
            Deleted Meal Guides
          </v-btn>
        </div>

        <v-btn
          class="bg-primary-gradient primary ml-auto"
          @click="$router.push({ name: 'form.meals' })"
          depressed
        >
          <v-icon class="mr-2"> {{ icons.add }} </v-icon>
          Add New Meal Guide
        </v-btn>
      </template>
    </main-app-bar>

    <div class="px-12 mb-10">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'MealGuidePage',

  components: {
    MainAppBar,
  },

  data() {
    return {
      addNew: false,
      icons: {
        add: mdiPlus,
      },
    }
  },
}
</script>
