<template>
  <div class="recipe">
    <main-app-bar>
      <template v-slot:title> Recipe </template>

      <template v-slot:actions>
        <div class="app-bar--bottom-actions nav-pills">
          <v-btn :to="{ name: 'active.recipe' }" depressed replace>
            Active Recipe Entries
          </v-btn>

          <v-btn :to="{ name: 'draft.recipe' }" depressed replace>
            Draft Recipe Entries
          </v-btn>

          <v-btn :to="{ name: 'deleted.recipe' }" depressed replace>
            Deleted Recipe Entries
          </v-btn>
        </div>

        <v-btn :to="{ name: 'import.recipe' }" class="ml-2">
          Import Recipes
        </v-btn>

        <v-btn
          class="bg-primary-gradient primary ml-auto"
          @click="$router.push({ name: 'form.recipe' })"
          depressed
        >
          <v-icon class="mr-2"> {{ icons.add }} </v-icon>
          Add New Recipe Entries
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-5 px-12 mb-10">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mdiPlus } from '@mdi/js'
import { mapActions } from 'vuex'

export default {
  name: 'RecipePage',

  components: {
    MainAppBar,
  },

  data() {
    return {
      addNew: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  methods: {
    ...mapActions({
      getFoodCategory: 'foodCategory/getDataList',
    }),
  },

  created() {
    this.getFoodCategory({ search: null })
  },
}
</script>
